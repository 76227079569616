<template>
  <div>
    <div class="warp">
      <div class="item-input">
        <div class="name required">商品名称</div>
        <input type="text" placeholder="请输入商品名称" v-model="formData.name">
      </div>
      <div class="item-input">
        <div class="name required">商品品类</div>
        <input type="text" placeholder="请输入选择商品品类" v-model="typeName" readonly @click="commodityType = true">
        <i class="icon-black-arrow"></i>
      </div>
    </div>
    <div class="fgx"></div>
    <div class="warp">
      <div class="item-input">
        <div class="name">商品品牌</div>
        <input type="text" placeholder="例如：蒙牛、国泰" v-model="formData.brand">
      </div>
      <div class="item-input">
        <div class="name">商品规格</div>
        <input type="text" placeholder="例如：250mlx24瓶/箱" v-model="formData.spec">
      </div>
      <div class="item-input">
        <div class="name">月需求量</div>
        <input type="text" placeholder="预计一个月的购买量" v-model="formData.requireQuantity">
      </div>
      <div class="item-input">
        <div class="name">原购买渠道</div>
        <input type="text" placeholder="例如：菜市场" v-model="formData.shopChannel">
      </div>
      <div class="item-input">
        <div class="name">备注信息</div>
        <input type="text" placeholder="品种、口味、产地、特殊要求" v-model="formData.remark">
      </div>
    </div>
    <div class="fgx"></div>
    <div class="warp">
      <div class="upload-img">
        <div class="name">上传照片<span class="ms">(最多三张)</span></div>
        <div class="imgs-box" >
          <div class="upload-btn img" v-if="imgArray.length < 3">
            <input type="file" class="file-input" accept="image/*" @change="choicePicture($event)" multiple="multiple">
          </div>
          <div class="img-bg img" v-for="(item, index) in imgArray" :key="index" :style="`background-image: url('${requestImgUrl(item.url)}')`">
            <img class="op-img" :src="requestImgUrl(item.url)" alt=""  preview="0">
            <img  src="" alt="">
            <div class="upload-fail" @click="deleteImg(index)" :style="`background-image:url('${require('@/assets/images/img/upload-fail.png')}')`"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-bottom">
      <div class="submit-btn" @click="Submit">提交</div>
    </div>

    <mt-popup class="w100" v-model="commodityType" position="bottom">
      <div class="select-popup-btn fix">
        <span class="btn fl" @click="commodityType = false">取消</span>
        <span class="btn fr" @click="modifyCommodity">确定</span>
      </div>
      <mt-picker
        :slots="commoditySlots"
        @change="commodityChange"
        value-key="name"
      ></mt-picker>
    </mt-popup>



  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { requestFileName, requestImgUrl } from "@/utils/common";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.requestFileName = (file, cb, isPrivate) => requestFileName(file, cb, isPrivate);
    return {
      // 商品分类名称
      commodityObj: {
        name: '',
        id: '',
      },
      typeName: '',
      // 表单信息
      formData: {
        brand: '',            // 品牌
        categoryFirst: '',    //分类id
        imgJson: '',          //图片json
        name: '',             //商品名称
        remark: '',           //备注
        requireQuantity: '',  //月需求量
        searchKey: '',        //搜索来源
        shopChannel: '',      //来源渠道
        spec: '',             //规格
      },
      // 商品分类弹窗
      commodityType: false,
      commoditySlots: [ 
        {
          flex: 1,
          values: [],
          className: "commodity-slots-class",
          textAlign: "center"
        }
      ],
      // 图片合集
      imgArray: [],
    }
  },
  mounted() {
    this.CommodityClass({
      level: 1,
    }).then((res)=> {
      this.commoditySlots[0].values = res.data;
    });
    if (this.$route.query.searchkey) {
      this.formData.searchKey = this.$route.query.searchkey;
    }
  },
  methods: {
    ...mapActions('shopping', [
      'CommodityClass',
      'postMallRequire',
    ]),
    Submit() {
      let formData = this.formData;
      if (formData.name == '') {
        this.$toast('商品名称');
         return false;
      } else if (formData.categoryFirst == '') {
        this.$toast('商品品类');
        return false;
      }
      formData.imgJson = JSON.stringify(this.imgArray);
      this.postMallRequire(formData).then((res)=> {
        this.$toast('提交成功');
        setTimeout(()=> {
          this.$router.go(-1);
        },2000)
      })
    },
    deleteImg(index) {
      this.imgArray.splice(index, 1);
    },
    choicePicture(e) {
      if (e.target.files.length == 0) {
        return false;
      }
      if (e.target.files.length > 3) {
        this.$toast('最多上传三张图片');
        return false;
      }
      this.$Indicator.open('上传图片中');
      const files = e.target.files
      let imgArray = [];
      for (var i = 0; i < files.length; i++) {
        const file = e.target.files[i];
        const success = i;
        this.requestFileName(file,(res)=> {
          if (success == files.length - 1) {
            setInterval(()=>{
              this.$Indicator.close();
            },500)
          }
          const img = {};
          if (Array.isArray(res.data)) {
            img.name = res.data[0].fileName;
            img.url = res.data[0].fileUri;
          } else {
            img.name = res.data.fileName;
            img.url = res.data.fileUri;
          }
          this.imgArray.push(img);
          this.$previewRefresh();
        }, false);
      }
    },
    commodityChange(picker, values) {
      this.commodityObj = values[0] ? values[0] : '';
    },
    modifyCommodity() {
      this.formData.categoryFirst = this.commodityObj.id;
      this.typeName = this.commodityObj.name;
      this.commodityType = false;
    },
  },
}
</script>
<style lang='less' scoped>
  .fixed-bottom{
    height: 1rem;
  }
  .submit-btn{
    height: 1rem;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 1rem;
    background: #ff7141;
    color: #fff;
    z-index: 99;
  }
  .upload-img{
    padding: .2rem;
    .imgs-box{
      display: flex;
      margin-top: .4rem;
      .img-bg{
        background-size: cover;
        position: relative;
        .upload-fail{
          position: absolute;
          top: -.2rem;
          right: 0;
          height: .5rem;
          width: .5rem;
          z-index: 3;
          background-size: .37rem .37rem;
          background-position: top right;
          background-repeat: no-repeat;
        }
        .op-img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      .upload-btn{
        border: 1px solid #f5f5f5;
        background-image: url('../../assets/images/img/upload-img.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50% 50%;
        position: relative;
        .file-input{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      .img{
        width: 1.8rem;
        height: 1.8rem;
        margin-right: .15rem;
      }
    }
    .name{
      font-size: .32rem;
      .ms{
        font-size: .26rem;
        color: #ccc;
      }
    }
  }
  .fgx{
    height: .15rem;
    background: #f5f5f5;
  }
  .item-input{
    display: flex;
    padding: 0 .2rem;
    height: 1rem;
    align-items: center;
    font-size: .32rem;
    border-bottom: 1px solid #f5f5f5;
    .name{
      width: 2rem;
      position: relative;
      &.required::after{
        position: absolute;
        content: '*';
        font-size: .3rem;
        top: 0.1rem;
        left: -.2rem;
        color: red;
      }
    }
    input{
      font-size: .32rem;
      flex: 1;
    }
    
  }
</style>